import React, { useEffect, useRef } from 'react';
import Navigation from '../Navigation';
import Hero from '../Hero';
import Gallery from '../Gallery';
import Services from '../Services';
import Footer from '../Footer';
import './HomePage.css';

export default function HomePage() {

    const galleryRef = useRef(null);
    const servicesRef = useRef(null);
    const footerRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('show');
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        if (galleryRef.current) {
            observer.observe(galleryRef.current);
        }

        if (servicesRef.current) {
            observer.observe(servicesRef.current);
        }

        if (footerRef.current) {
            observer.observe(footerRef.current);
        }

        return () => {
            if (galleryRef.current) {
                observer.unobserve(galleryRef.current);
            }

            if (servicesRef.current) {
                observer.unobserve(servicesRef.current);
            }

            if (footerRef.current) {
                observer.unobserve(footerRef.current);
            }
        }
    }, []);

    return (
        <div>
            <Navigation />

            <Hero />

            {/* <div ref={galleryRef} className='body-fade-in' id='gallery'>
                <Gallery />
            </div> */}

            {/* <div ref={servicesRef} className='body-fade-in' id='services'>
                <Services />
            </div> */}

            <div ref={footerRef} className='body-fade-in' id='footer'>
                <Footer />
            </div>
        </div>
    )
}