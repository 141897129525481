import { BrowserRouter as Router } from 'react-router-dom';
import Main from './components/Main/Main.js';
import { ModalProvider } from './context/Modal.js';
import './App.css';

function App() {
  return (
    <ModalProvider>
      <Router>
        <Main />
      </Router>
    </ModalProvider>
  );
}

export default App;
