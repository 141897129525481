export const englishContent = {
    heroDescription1: "Empowering Communities Through Inclusive Opportunities",
    heroDescription2: "Connecting Minority-Owned Businesses with Meaningful Projects and Partnerships.",
    heroDescription3: "At Latinos Unidos Services, we are dedicated to enriching our community by providing a comprehensive range of quality services. From construction to landscaping, painting, snow plowing, and waste management, our mission is to bridge the gap between local, minority-owned businesses and substantial opportunities.",
    heroDescription4: "In collaboration with partners like Luminis Health, we foster an inclusive environment where excellence, community support, and sustainability are at the forefront of every project. Join us in shaping a vibrant, resilient community, one service at a time.",
    viewWork: "View Work",
    viewServices: "View Services and Estimates",
    contactUs: "Contact Us"
};

export const spanishContent = {
    heroDescription1: "Empoderando Comunidades a Través de Oportunidades Inclusivas",
    heroDescription2: "Conectando Negocios de Propiedad de Minorías con Proyectos y Alianzas Significativos.",
    heroDescription3: "En Latinos Unidos Services, estamos dedicados a enriquecer nuestra comunidad proporcionando una amplia gama de servicios de calidad. Desde la construcción hasta la jardinería, pintura, limpieza de nieve y gestión de residuos, nuestra misión es cerrar la brecha entre los negocios locales de propiedad de minorías y oportunidades sustanciales.",
    heroDescription4: "En colaboración con socios como Luminis Health, fomentamos un entorno inclusivo donde la excelencia, el apoyo comunitario y la sostenibilidad están al frente de cada proyecto. Únete a nosotros para dar forma a una comunidad vibrante y resiliente, un servicio a la vez.",
    viewWork: "Ver Trabajo",
    viewServices: "Ver Servicios y Presupuestos",
    contactUs: "Contáctenos",
};
