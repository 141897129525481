import React, { useState } from 'react';
import { useLanguage } from '../../LanguageContext';
import { englishContent, spanishContent } from './content';
import shake from '../../assets/images/handshake-cropped.jpg';
import map_pin from '../../assets/icons/map-pin.svg';
import phone from '../../assets/icons/phone-icon.svg';
import './Hero.css';

export default function Hero() {
    const { currentLanguage, setCurrentLanguage } = useLanguage();

    const toggleLanguage = () => {
        setCurrentLanguage(currentLanguage === 'english' ? 'spanish' : 'english');
    };

    const content = currentLanguage === 'english' ? englishContent : spanishContent;

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        const yOffset = -100;
        const topOffset = sectionElement.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: topOffset, behavior: 'smooth' });
    };

    return (
        <header className='header-container'>
            <div className='hero-section'>
                <div className='hero-information'>
                    <h1 className='title fade1'>Latinos Unidos Services</h1>
                    <h2 className='location fade2'>Annapolis, Maryland</h2>
                    <button className='hero-language-toggle-button language-toggle-button fade1' onClick={toggleLanguage}>
                        {currentLanguage === 'english' ? 'Español' : 'English'}
                    </button>

                    <div className='hero-descriptions-container fade2'>
                        <h3 className='description'>
                            {content.heroDescription1}
                        </h3>

                        <h3 className='description description-2'>
                            {content.heroDescription2}
                        </h3>

                        <h3 className='description description-3'>
                            {content.heroDescription3}
                        </h3>

                        <h3 className='description description-3'>
                            {content.heroDescription4}
                        </h3>
                    </div>

                    <div className='hero-buttons-container fade2'>
                        {/* <button className='view-gallery-hero' onClick={() => scrollToSection('gallery')}>
                            {content.viewWork}
                        </button>

                        <button className='view-services-hero' onClick={() => scrollToSection('services')}>
                            {content.viewServices}
                        </button> */}
                    </div>

                    <div className='hero-image-and-contact fade3'>
                        <div className='hero-image-container'>
                            <img src={shake} className='hero-image' alt='Latinos Unidos Services Hero Picture' />
                            <div className='gradient-overlay'>
                                <div className='contact-container'>
                                    <div className='contact-heading-container'>
                                        <h3 className='contact-heading-text'>{content.contactUs}</h3>
                                    </div>

                                    <div className='contact-heading-container'>
                                        <h3 className='contact-heading-text'>
                                            info@latinosunidosservicesllc.com
                                        </h3>
                                    </div>

                                    <div className='footer-icon-container'>
                                        <p className='footer-icon-description'>
                                            <span className='image-location'>
                                                <img src={map_pin} className='footer-icon' alt="Map Pin Icon" />
                                                Annapolis, Maryland
                                            </span>
                                        </p>
                                    </div>

                                    {/* <div className='footer-icon-container'>
                                        <img src={phone} className='footer-icon' alt='Phone Icon' />
                                        <p className='footer-icon-description'>
                                            443-481-9344
                                        </p>
                                    </div> */}

                                    {/* <div className='footer-icon-container'>
                                        <p className='footer-icon-description'>social here
                                        </p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}