import { useLanguage } from '../../LanguageContext';
import { englishContent, spanishContent } from './content';
import { Link } from 'react-router-dom';
import logo from '../../assets/icons/cultural-assimilation.png';
import map_pin from '../../assets/icons/map-pin.svg';
import './Footer.css';

export default function Footer() {
    const { currentLanguage, setCurrentLanguage } = useLanguage();
    const content = currentLanguage === 'english' ? englishContent : spanishContent;

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        sectionElement.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <footer className='footer-container' id='footer'>
            <div className='footer-section'>
                <section className='logo-section'>
                    <img src={logo} className='big-logo' alt="Big Logo" onClick={() => scrollToTop()}></img>
                </section>

                <section className='second-footer-section'>
                    <ul className='footer-options-container'>
                        <li className='footer-heading'>                  Latinos Unidos Services
                        </li>

                    </ul>
                    <div className='footer-options'>
                        {/* <li> */}
                        <div className='footer-icon-container'>
                            <p className='footer-icon-description'>
                                <span>
                                    <img src={map_pin} className='footer-icon' alt="Map Pin Icon" />
                                    Annapolis, Maryland
                                </span>
                            </p>
                        </div>
                        {/* </li> */}
                    </div>
                </section>

                <section className='third-footer-section'>
                    <ul className='footer-options-container'>
                        <li className='footer-heading'>
                            info@latinosunidosservicesllc.com
                            443-481-9344
                        </li>
                    </ul>
                </section>

            </div>
            <div className='credits-container'>
                <p className='credits-created-by'>{content.credits}</p>
                <a href='https://www.isaiahxs.com' target='_blank' rel='noopener noreferrer' className='isaiah'>
                    Isaiah Sinnathamby
                </a>
            </div>
        </footer>
    )
}