import React, { useState, useEffect, useRef } from 'react';
import { useLanguage } from '../../LanguageContext';
import { englishContent, spanishContent } from './content';
import logo from '../../assets/icons/cultural-assimilation.png';
import hamburger from '../../assets/icons/hamburger.png';
import './Navigation.css';

export default function Navigation() {
    const { currentLanguage, setCurrentLanguage } = useLanguage();
    const content = currentLanguage === 'english' ? englishContent : spanishContent;

    const navRef = useRef();
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleLanguage = () => {
        setCurrentLanguage(currentLanguage === 'english' ? 'spanish' : 'english');
    };

    const toggleNavOpen = () => {
        setIsNavOpen(!isNavOpen);
    }

    useEffect(() => {
        if (isNavOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isNavOpen]);

    useEffect(() => {
        const checkIfClickOutside = e => {
            //if menu is open and the clicked target is not within the menu, then close the menu
            if (isNavOpen && navRef.current && !navRef.current.contains(e.target)) {
                setIsNavOpen(false);
            }
        }

        document.addEventListener("mousedown", checkIfClickOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickOutside);
        };
    }, [isNavOpen]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <nav className='nav-bar'>
            <div className='nav-options'>
                <div className='nav-logo-container'>
                    <img src={logo} className='small-logo fade-in' alt="Logo" onClick={() => scrollToTop()} />
                </div>

                <div className='nav-name fade-in'>
                    Latinos Unidos Services
                </div>

                <div className='nav-logo-container'>
                    {/* hamburger icon here */}
                    <img src={hamburger} className='small-logo hamburger-menu fade-in' alt="Hamburger Menu" onClick={toggleNavOpen} />
                </div>

                {isNavOpen && <div className='backdrop'></div>}

                {/* panel */}
                <div ref={navRef} className={`nav-panel ${isNavOpen ? 'nav-open' : ''}`}>
                    <button className='x-button' onClick={toggleNavOpen}>X</button>

                    <div className='panel-buttons'>
                        <div>
                            <button className='language-toggle-button panel-language-button' onClick={toggleLanguage}>
                                <p className='nav-button-text'>
                                    {currentLanguage === 'english' ? 'Español' : 'English'}
                                </p>
                            </button>
                        </div>

                        <div>
                            {/* <a href="https://www.google.com/" target='_blank' rel='noopener noreferrer'> */}
                            <button className='nav-button panel-button'>
                                <div>
                                    <p className='nav-button-text'>
                                        {content.painting}
                                    </p>
                                </div>
                            </button>
                            {/* </a> */}
                        </div>

                        <div>
                            {/* <a href='https://www.google.com/' target='_blank' rel='noopener noreferrer'> */}
                            <button className='nav-button panel-button'>
                                <div>
                                    <p className='nav-button-text'>
                                        {content.construction}
                                    </p>
                                </div>
                            </button>
                            {/* </a> */}
                        </div>

                        <div>
                            {/* <a href="https://www.google.com/" target='_blank' rel='noopener noreferrer'> */}
                            <button className='nav-button panel-button'>
                                <div>
                                    <p className='nav-button-text'>
                                        {content.janitorialServices}
                                    </p>
                                </div>
                            </button>
                            {/* </a> */}
                        </div>

                        <div>
                            {/* <a href="https://www.google.com/" target='_blank' rel='noopener noreferrer'> */}
                            <button className='nav-button panel-button'>
                                <div>
                                    <p className='nav-button-text'>
                                        {content.landscaping}
                                    </p>
                                </div>
                            </button>
                            {/* </a> */}
                        </div>

                        <div className='nav-logo-container'>
                            <img src={logo} className='big-logo panel-logo' alt="Big Logo" />
                        </div>

                        <div className='panel-contact'>
                            <h3>{content.contactUs}</h3>
                            <h4>info@latinosunidosservicesllc.com</h4>
                            <h4>443-481-9344</h4>
                        </div>

                    </div>
                </div>
            </div>
        </nav >
    )
}