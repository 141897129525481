export const englishContent = {
    socials: "Social Media",
    credits: "Website designed and created by",
};

export const spanishContent = {

    socials: "Redes Sociales",
    credits: "Sitio web diseñado y creado por",
};
