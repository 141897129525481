export const englishContent = {
    painting: "Painting",
    construction: "Construction",
    janitorialServices: "Janitorial Services",
    landscaping: "Landscaping",
    contactUs: "Contact Us",
    email: "info@latinosunidosservicesllc.com"
};

export const spanishContent = {
    painting: "Pintura",
    construction: "Construcción",
    janitorialServices: "Servicios de Limpieza",
    landscaping: "Jardinería",
    contactUs: "Contáctenos",
    email: "info@latinosunidosservicesllc.com"
};
